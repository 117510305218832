import { colors } from "../hooks/color";
import { useUser } from "../hooks/user";
import RoundButton from "../components/RoundButton";
import { useEffect, useState } from "react";
import Avatar from "../components/Avatar";
import { fetchData } from "../api";
import { useNavigate } from "react-router-dom";

type Showing = "upcoming" | "hosting" | "past";

interface HomeEvent {
  event_code: string;
  image: string;
  title: string;
  host_image: string;
  host_nickname: string;
  status?: "going" | "not_going" | "hosting";
  is_past?: boolean;
  date: string;
  start_time: string;
}

function Home() {
  const [showing, setShowing] = useState<Showing>("upcoming");
  const [homeEvents, setHomeEvents] = useState<HomeEvent[] | null>(null);
  const user = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    fetchData<HomeEvent[]>("GET", `/events/home?showing=${showing}`).then(
      setHomeEvents
    );
  }, [showing]);

  if (!user || !homeEvents) return null;
  return (
    <div style={{ padding: 15 }}>
      <div
        style={{
          fontSize: 36,
          fontWeight: 500,
        }}
      >
        Hi {user.meta.nickname}.
        <br />
        Are you ready for
        <br />
        some food?
      </div>
      <div style={{ marginTop: 30, display: "flex", columnGap: 10 }}>
        <RoundButton
          color={colors.black}
          handleClick={() => setShowing("upcoming")}
          backgroundColor={showing === "upcoming" ? colors.blue : colors.ivory}
        >
          Upcoming
        </RoundButton>
        <RoundButton
          color={colors.black}
          handleClick={() => setShowing("hosting")}
          backgroundColor={showing === "hosting" ? colors.blue : colors.ivory}
        >
          Hosting
        </RoundButton>
        <RoundButton
          color={colors.black}
          handleClick={() => setShowing("past")}
          backgroundColor={showing === "past" ? colors.blue : colors.ivory}
        >
          Past
        </RoundButton>
      </div>
      {homeEvents.length === 0 && (
        <div
          style={{
            marginTop: 30,

            fontSize: 24,
            fontWeight: 500,
          }}
        >
          No events available
        </div>
      )}
      {homeEvents.length > 0 && (
        <div
          style={{
            marginTop: 30,
            marginLeft: -15,
            marginRight: -15,
            padding: "0px 15px",
            display: "flex",
            columnGap: 15,
            overflow: "auto",
          }}
        >
          {homeEvents.map((homeEvent, index) => (
            <div key={index}>
              <div
                style={{
                  width: 315,
                  height: 315,
                  borderRadius: 24,
                  backgroundColor: colors.grey,
                  overflow: "hidden",
                  cursor: "pointer",
                  position: "relative",
                }}
                onClick={() => navigate(`/events/${homeEvent.event_code}`)}
              >
                <div style={{ position: "absolute", top: 15, left: 15 }}>
                  <RoundButton>
                    {(() => {
                      // Input value
                      const dateStr = homeEvent.date;
                      const timeStr = homeEvent.start_time; // Example: 24-hour time format
                      // Split the time string into hours and minutes
                      const dateSplitted = dateStr.split("-").map(Number);
                      const month = dateSplitted[1];
                      const day = dateSplitted[2];
                      const [hour, minute] = timeStr.split(":").map(Number);

                      // Convert to 12-hour format
                      const period = hour >= 12 ? "PM" : "AM";
                      const formattedDate = `${month}/${day}`;
                      const formattedHour = hour % 12 || 12; // Convert 0 to 12 for midnight
                      const formattedTime =
                        minute === 0
                          ? `${formattedHour} ${period}` // No minutes if they are 0
                          : `${formattedHour}:${minute
                              .toString()
                              .padStart(2, "0")}${period}`;
                      return `${formattedDate}, ${formattedTime}`;
                    })()}
                  </RoundButton>
                </div>
                {homeEvent.status && (
                  <div style={{ position: "absolute", bottom: 15, right: 15 }}>
                    <RoundButton
                      color={colors.black}
                      backgroundColor={(() => {
                        if (homeEvent.status === "hosting") return colors.blue;
                        if (homeEvent.status === "going") return colors.yellow;
                        if (homeEvent.status === "not_going")
                          return colors.ivory;
                        return "";
                      })()}
                    >
                      {homeEvent.status === "hosting" && "Hosting"}
                      {homeEvent.status === "going" && "Going"}
                      {homeEvent.status === "not_going" && "Not Going"}
                    </RoundButton>
                  </div>
                )}
                <img
                  src={homeEvent.image}
                  alt=""
                  draggable={false}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </div>
              <div style={{ marginTop: 15, fontSize: 20, fontWeight: 700 }}>
                {homeEvent.title}
              </div>
              <div
                style={{
                  marginTop: 15,
                  display: "flex",
                  alignItems: "center",
                  columnGap: 5,
                  fontSize: 12,
                  color: colors.grey,
                }}
              >
                Hosted by
                <Avatar width={20} height={20} src={homeEvent.host_image} />
                {homeEvent.host_nickname}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Home;
