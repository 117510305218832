import { useContext, useEffect, useState } from "react";
import TextInput from "../../inputs/TextInput";
import SelectInput from "../../inputs/SelectInput";
import { USStates, allergens } from "../../const";
import { fetchData } from "../../api";
import { useNavigate, useParams } from "react-router-dom";
import { UploadPhotoImage } from "../../components/UploadPhotos";
import { colors } from "../../hooks/color";
import { useImageUpload } from "../../hooks/image";
import { v4 } from "uuid";
import { GlobalContext } from "../../App";
import RoundButton from "../../components/RoundButton";
import CustomDialog from "../../components/CustomDialog";
import ScrollSelect from "../../inputs/ScrollSelect";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import TimeUtil from "../../utils/time";
import AddIcon from "@mui/icons-material/Add";
import CustomImage from "../../components/CustomImage";
import Backward from "../../components/Backward";
import CustomSwiper from "../../components/CustomSwiper";
import { EventMenu } from "../../interfaces";
import { SwiperClass } from "swiper/react";
import UploadImagePng from "../../pngs/upload_image.png";
import Header from "../../components/Header";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as EditSvg } from "../../svgs/edit.svg";

interface UploadPhotosComponentProps {
  images: UploadPhotoImage[];
  setImages: (images: UploadPhotoImage[]) => void;
  handleBack: () => void;
}

function UploadPhotosComponent({
  images,
  setImages,
  handleBack,
}: UploadPhotosComponentProps) {
  const [tempImages, setTempImages] = useState<UploadPhotoImage[]>([]);
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const [fileInputRef, setFileInputRef] = useState<HTMLInputElement | null>(
    null
  );
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    setTempImages([...images]);
  }, [images]);

  useEffect(() => {
    if (!ref) return;
    const width = ref.offsetWidth - 30;
    setHeight(width / 3);
  }, [ref]);

  const disableScroll = (event: TouchEvent) => {
    event.preventDefault();
  };

  useEffect(() => {
    document.addEventListener("touchmove", disableScroll, { passive: false });
    return () => document.removeEventListener("touchmove", disableScroll);
  }, []);

  return (
    <div
      style={{ height: "100%", display: "flex", flexDirection: "column" }}
      ref={setRef}
    >
      <Header label="Add Photos" handleBack={handleBack} />
      <div
        style={{
          padding: 15,
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <GridContextProvider
          onChange={(sourceId, sourceIndex, targetIndex, targetId) => {
            const nextState = swap(tempImages, sourceIndex, targetIndex);
            setTempImages([...nextState]);
          }}
        >
          <GridDropZone
            boxesPerRow={3}
            id="items"
            rowHeight={height}
            style={{ flex: 1, overflow: "auto" }}
          >
            {tempImages.map((image, index) => (
              <GridItem key={image.location}>
                <div
                  style={{
                    padding: 5,
                    width: "100%",
                    height: "100%",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                    }}
                  >
                    <RoundButton
                      style={{
                        borderRadius: "50%",
                        width: 30,
                        height: 30,
                        padding: 5,
                      }}
                      backgroundColor={colors.ivory}
                      handleClick={() => {
                        images.splice(index, 1);
                        setTempImages([...images]);
                      }}
                    >
                      <CloseIcon
                        style={{
                          color: colors.black,
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </RoundButton>
                  </div>
                  <CustomImage
                    src={image.location}
                    width="100%"
                    height="100%"
                    style={{
                      borderRadius: 10,
                      overflow: "hidden",
                    }}
                  />
                </div>
              </GridItem>
            ))}
          </GridDropZone>
        </GridContextProvider>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: 30,
            marginBottom: 30,
          }}
        >
          <RoundButton
            backgroundColor={colors.yellow}
            color={colors.black}
            handleClick={() => {
              if (!fileInputRef) return;
              fileInputRef.click();
            }}
          >
            Add photos
            <input
              type="file"
              name="file"
              accept="image/*"
              multiple
              onChange={(event) => {
                const files = event.target.files;
                if (!files || files.length === 0) return;
                setTempImages([
                  ...tempImages,
                  ...Array.from(files).map((file) => ({
                    file,
                    location: URL.createObjectURL(file),
                  })),
                ]);
                event.target.value = "";
              }}
              ref={setFileInputRef}
              style={{ display: "none" }}
            />
          </RoundButton>
          <RoundButton
            backgroundColor={colors.ivory}
            color={colors.black}
            handleClick={() => {
              setImages([...tempImages]);
              handleBack();
            }}
          >
            Save
          </RoundButton>
        </div>
      </div>
    </div>
  );
}

interface LocationComponentProps {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipcode: string;
  handleSave: (
    newAddress1: string,
    newAddress2: string,
    newCity: string,
    newState: string,
    newZipcode: string
  ) => void;
}

function LocationComponent({
  address1,
  address2,
  city,
  state,
  zipcode,
  handleSave,
}: LocationComponentProps) {
  const [openLocation, setOpenLocation] = useState<boolean>(false);
  const [newAddress1, setNewAddress1] = useState<string>("");
  const [newAddress2, setNewAddress2] = useState<string>("");
  const [newCity, setNewCity] = useState<string>("");
  const [newState, setNewState] = useState<string>("");
  const [newZipcode, setNewZipcode] = useState<string>("");
  useEffect(() => {
    setNewAddress1(address1);
    setNewAddress2(address2);
    setNewCity(city);
    setNewState(state);
    setNewZipcode(zipcode);
  }, [address1, address2, city, state, zipcode]);

  return (
    <>
      <TextInput
        readOnly
        placeholder="Location"
        value={[address1, address2, city, state, zipcode]
          .filter(Boolean)
          .join(", ")}
        multiline
        handleClick={() => setOpenLocation(true)}
        frontLogo={
          <svg
            width="16"
            height="18"
            viewBox="0 0 16 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.5 0C4.91589 0 2 2.80332 2 6.24888C2 9.69443 8.00063 17.0277 8.25582 17.3423L8.5 17.6429L8.74418 17.3423C8.99937 17.0276 15 9.59938 15 6.24871C15 2.89805 12.0839 0 8.49983 0H8.5ZM8.5 3.57069C10.0361 3.57069 11.2857 4.772 11.2857 6.24871C11.2857 7.72542 10.0361 8.92673 8.5 8.92673C6.9639 8.92673 5.71429 7.72542 5.71429 6.24871C5.71429 4.772 6.9639 3.57069 8.5 3.57069Z"
              fill="#FFFAF6"
            />
          </svg>
        }
      />
      <CustomDialog
        open={openLocation}
        handleClose={() => setOpenLocation(false)}
        fullWidth
        dark
      >
        <div style={{ display: "flex", flexDirection: "column", rowGap: 15 }}>
          <div>
            <TextInput
              name="address1"
              label="Street Address"
              handleChange={setNewAddress1}
              value={newAddress1}
            />
          </div>
          <div>
            <TextInput
              name="address2"
              label="Apt. / Suite Number (Optional)"
              handleChange={setNewAddress2}
              value={newAddress2}
            />
          </div>
          <div>
            <TextInput
              name="city"
              label="City"
              handleChange={setNewCity}
              value={newCity}
            />
          </div>
          <div>
            <SelectInput
              name="state"
              label="State"
              value={newState}
              items={USStates.map((state) => ({
                value: state,
                label: state,
              }))}
              handleChange={setNewState}
            />
          </div>
          <div>
            <TextInput
              name="zipcode"
              label="Zip Code"
              handleChange={setNewZipcode}
              maxLength={5}
              value={newZipcode}
            />
          </div>
          <div style={{ marginTop: 15, textAlign: "center" }}>
            <RoundButton
              backgroundColor={colors.blue}
              color={colors.black}
              handleClick={() => {
                handleSave(
                  newAddress1,
                  newAddress2,
                  newCity,
                  newState,
                  newZipcode
                );
                setOpenLocation(false);
              }}
            >
              Save
            </RoundButton>
          </div>
        </div>
      </CustomDialog>
    </>
  );
}

interface DateTimeComponentProps {
  date: string;
  startTime: string;
  endTime: string;
  handleSave: (
    newDate: string,
    newStartTime: string,
    newEndTime: string
  ) => void;
}

function DateTimeComponent({
  date,
  startTime,
  endTime,
  handleSave,
}: DateTimeComponentProps) {
  const [openDate, setOpenDate] = useState<boolean>(false);
  const [newDate, setNewDate] = useState<string>("");
  const [newStartTime, setNewStartTime] = useState<string>("");
  const [newEndTime, setNewEndTime] = useState<string>("");

  useEffect(() => {
    setNewDate(date);
    setNewStartTime(startTime);
    setNewEndTime(endTime);
  }, [date, startTime, endTime]);

  return (
    <>
      <TextInput
        readOnly
        placeholder="Date and Time"
        value={
          date && startTime && endTime
            ? TimeUtil.formatDateStartEndTime(date, startTime, endTime)
            : ""
        }
        handleClick={() => setOpenDate(true)}
        frontLogo={
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.61842 2H0V5.0115L16 5.0115V2H13.3816V3.80473H11.8816V2H4.11842L4.11842 3.80473H2.61842L2.61842 2ZM0 6.5115L16 6.5115V14.6316H0V6.5115Z"
              fill="#FFFAF6"
            />
          </svg>
        }
      />
      <CustomDialog
        open={openDate}
        handleClose={() => setOpenDate(false)}
        fullWidth
        dark
      >
        <div style={{ display: "flex", flexDirection: "column", rowGap: 15 }}>
          <div>
            {(() => {
              const minDateString = TimeUtil.getNowDateString();
              return (
                <TextInput
                  fullWidth
                  type="date"
                  label="Date"
                  handleChange={(date) => {
                    if (date < minDateString) return;
                    setNewDate(date);
                  }}
                  value={newDate}
                  inputProps={{
                    min: minDateString,
                  }}
                />
              );
            })()}
          </div>
          <div>
            <TextInput
              fullWidth
              type="time"
              label="Start Time"
              handleChange={setNewStartTime}
              value={newStartTime}
            />
          </div>
          <div>
            <TextInput
              fullWidth
              type="time"
              label="End Time"
              handleChange={setNewEndTime}
              value={newEndTime}
            />
          </div>
          <div style={{ marginTop: 15, textAlign: "center" }}>
            <RoundButton
              backgroundColor={colors.blue}
              color={colors.black}
              handleClick={() => {
                handleSave(newDate, newStartTime, newEndTime);
                setOpenDate(false);
              }}
            >
              Save
            </RoundButton>
          </div>
        </div>
      </CustomDialog>
    </>
  );
}

interface MenuCardComponentProps {
  menu: EventMenu;
  index: number;
  menus: EventMenu[];
  setMenus: (menus: EventMenu[]) => void;
  addMenu: () => void;
}

function MenuCardComponent({
  menu,
  index,
  menus,
  setMenus,
  addMenu,
}: MenuCardComponentProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [menuAllergens, setMenuAllergens] = useState<string[]>([]);
  return (
    <div
      style={{
        borderRadius: 20,
        backgroundColor: colors.blue,
        padding: 15,
        marginBottom: 5,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          columnGap: 15,
        }}
      >
        <RoundButton
          backgroundColor={colors.black}
          color={colors.blue}
          style={{ marginRight: "auto" }}
        >
          Menu {index + 1}
        </RoundButton>
        {index === menus.length - 1 && (
          <RoundButton
            backgroundColor={colors.ivory}
            color={colors.black}
            handleClick={addMenu}
          >
            <AddIcon />
            <div>Add Menu</div>
          </RoundButton>
        )}
        {menus.length > 1 && (
          <div
            style={{
              width: 32,
              height: 32,
              cursor: "pointer",
              display: "flex",
            }}
            onClick={() => {
              menus.splice(index, 1);
              setMenus([...menus]);
            }}
          >
            <svg
              width="18"
              height="22"
              viewBox="0 0 18 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                width: "100%",
                height: "100%",
              }}
            >
              <path
                d="M1.00016 2.9165H17V6.45319H1.00016V2.9165Z"
                stroke="#242525"
                strokeMiterlimit="10"
              />
              <path
                d="M6.85889 0.649414H11.1416V2.91544H6.85889V0.649414Z"
                stroke="#242525"
                strokeMiterlimit="10"
              />
              <path
                d="M2.59592 6.47705H8.99905H15.4042V21.3499H8.99905H2.59592V6.47705Z"
                stroke="#242525"
                strokeMiterlimit="10"
              />
              <path
                d="M11.1411 8.52295V19.2986"
                stroke="#242525"
                strokeMiterlimit="10"
              />
              <path
                d="M6.86023 8.52295V19.2986"
                stroke="#242525"
                strokeMiterlimit="10"
              />
            </svg>
          </div>
        )}
      </div>
      <div style={{ marginTop: 10 }}>
        <TextInput
          dark
          fontBold
          placeholder="Menu Title"
          handleChange={(title) => {
            if (title.length > 50) return;
            menu.title = title;
            setMenus([...menus]);
          }}
          value={menu.title}
        />
      </div>
      <div style={{ marginTop: 10 }}>
        <TextInput
          dark
          placeholder="Description"
          handleChange={(description) => {
            if (description.length > 100) return;
            menu.description = description;
            setMenus([...menus]);
          }}
          multiline
          rows={3}
          value={menu.description}
        />
      </div>
      <div style={{ marginTop: 10 }}>
        <TextInput
          fontBold
          dark
          placeholder="Allergens"
          readOnly
          frontLogo={
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="9" cy="9" r="9" fill="#242525" />
              <path
                d="M8.52637 13.339V12.1188H9.70679V13.339H8.52637ZM8.52637 10.9517V3.78955H9.70679V10.9517H8.52637Z"
                fill="#94C3F8"
              />
            </svg>
          }
          handleClick={() => setOpen(true)}
          value={menu.allergens.length > 0 ? menu.allergens.join(", ") : ""}
        />
      </div>
      <CustomDialog open={open} handleClose={() => setOpen(false)} fullWidth>
        <ScrollSelect
          items={allergens.map((allergen) => ({
            label: allergen,
            value: allergen,
          }))}
          handleChange={setMenuAllergens}
          label="Allergens"
          values={menuAllergens}
        />
        <div style={{ marginTop: 15, textAlign: "center" }}>
          <RoundButton
            backgroundColor={colors.blue}
            color={colors.black}
            handleClick={() => {
              menu.allergens = menuAllergens;
              setMenus([...menus]);
              setOpen(false);
            }}
          >
            Save
          </RoundButton>
        </div>
      </CustomDialog>
    </div>
  );
}

interface Event {
  id: number;
  created_at: string;
  meta: EventMeta;
}

interface EventMeta {
  event_code: string;
  images: string[];
  title: string;
  description: string;
  entry_instruction: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipcode: string;
  date: string;
  start_time: string;
  end_time: string;
  capacity: number;
  price: number;
  home_rule: string;
  menus: EventMenu[];
  venmo: string;
}

interface EventEditProps {
  handleBack?: () => void;
}

function EventsDetailEdit({ handleBack }: EventEditProps) {
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [images, setImages] = useState<UploadPhotoImage[]>([]);
  const [entryInstruction, setEntryInstruction] = useState<string>("");
  const [address1, setAddress1] = useState<string>("");
  const [address2, setAddress2] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [zipcode, setZipcode] = useState<string>("");
  const [date, setDate] = useState<string>("");
  const [startTime, setStartTime] = useState<string>("");
  const [endTime, setEndTime] = useState<string>("");
  const [capacity, setCapacity] = useState<number>(0);
  const [price, setPrice] = useState<number>(0);
  const [venmo, setVenmo] = useState<string>("");
  const [menus, setMenus] = useState<EventMenu[]>([
    {
      id: v4(),
      description: "",
      title: "",
      allergens: [],
    },
  ]);
  const [homeRule, setHomeRule] = useState<string>("");
  const { imageUpload } = useImageUpload();
  const { toggleSpinner } = useContext(GlobalContext);
  const navigate = useNavigate();
  const { eventCode } = useParams();
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const [current, setCurrent] = useState<number>(0);
  const [openUploadPhotos, setOpenUploadPhotos] = useState<boolean>(false);
  if (eventCode) {
    handleBack = () => navigate(`/events/${eventCode}`);
  }

  useEffect(() => {
    if (!swiper) return;
    swiper.on("activeIndexChange", (swiper: SwiperClass) => {
      setCurrent(swiper.activeIndex);
    });
  }, [swiper]);

  useEffect(() => {
    setCurrent(0);
  }, [images, openUploadPhotos]);

  useEffect(() => {
    if (!eventCode) return;
    fetchData<Event>("GET", `/events/${eventCode}`).then((event) => {
      const {
        images,
        title,
        description,
        entry_instruction,
        address1,
        address2,
        city,
        state,
        zipcode,
        date,
        start_time,
        end_time,
        capacity,
        price,
        home_rule,
        menus,
        venmo,
      } = event.meta;
      setTitle(title);
      setDescription(description);
      setImages(images.map((image) => ({ file: null, location: image })));
      setEntryInstruction(entry_instruction);
      setAddress1(address1);
      setAddress2(address2);
      setCity(city);
      setState(state);
      setZipcode(zipcode);
      setDate(date);
      setStartTime(start_time);
      setEndTime(end_time);
      setCapacity(capacity);
      setPrice(price);
      setVenmo(venmo);
      setHomeRule(home_rule);
      setMenus(menus);
    });
  }, [eventCode]);

  const handleSave = async () => {
    toggleSpinner();
    // do image first
    const event_images = await Promise.all(
      images.map((image) => {
        if (!image.file) {
          return image.location;
        }
        return imageUpload(image.file).then((image) => image.location);
      })
    );
    if (!eventCode) {
      fetchData<Event>("POST", "/events", {
        title,
        description,
        images: event_images,
        entry_instruction: entryInstruction,
        address1,
        address2,
        city,
        state,
        zipcode,
        date,
        start_time: startTime,
        end_time: endTime,
        capacity,
        price,
        venmo,
        home_rule: homeRule,
        menus,
      })
        .then((hostEvent) => {
          toggleSpinner();
          navigate(`/events/${hostEvent.meta.event_code}`);
        })
        .catch(toggleSpinner);
    } else {
      fetchData<Event>("PUT", `/events/${eventCode}`, {
        title,
        description,
        images: event_images,
        entry_instruction: entryInstruction,
        address1,
        address2,
        city,
        state,
        zipcode,
        date,
        start_time: startTime,
        end_time: endTime,
        capacity,
        price,
        venmo,
        home_rule: homeRule,
        menus,
      })
        .then((hostEvent) => {
          toggleSpinner();
          navigate(`/events/${hostEvent.meta.event_code}`);
        })
        .catch(toggleSpinner);
    }
  };

  let disabledCondition = false;
  if (
    !(
      Boolean(images.length > 0) &&
      Boolean(title) &&
      Boolean(description) &&
      Boolean(address1) &&
      Boolean(city) &&
      Boolean(state) &&
      Boolean(zipcode) &&
      Boolean(date) &&
      Boolean(startTime) &&
      Boolean(endTime) &&
      Boolean(capacity) &&
      Boolean(menus.length > 0)
    )
  ) {
    disabledCondition = true;
  }
  if (price > 0 && !Boolean(venmo)) {
    disabledCondition = true;
  }
  menus.forEach((menu) => {
    if (!(Boolean(menu.title) && Boolean(menu.description))) {
      disabledCondition = true;
    }
  });

  if (openUploadPhotos)
    return (
      <UploadPhotosComponent
        images={images}
        setImages={setImages}
        handleBack={() => setOpenUploadPhotos(false)}
      />
    );

  return (
    <>
      <Backward type="inverted" handleClick={handleBack} />
      <div
        style={{
          position: "relative",
        }}
      >
        {images.length === 0 && (
          <CustomImage height={300} src={UploadImagePng} />
        )}
        {images.length > 0 && (
          <CustomSwiper
            slides={images.map((image, index) => (
              <CustomImage height={300} src={image.location} key={index} />
            ))}
            setSwiper={setSwiper}
          />
        )}

        <div
          style={{
            zIndex: 1,
            position: "absolute",
            bottom: 30,
            right: 15,
            display: "flex",
            alignItems: "center",
          }}
        >
          {images.length > 0 && (
            <div style={{ marginRight: 15 }}>
              <RoundButton
                style={{
                  opacity: 0.8,
                }}
              >
                {current + 1}/{images.length}
              </RoundButton>
            </div>
          )}
          <RoundButton
            style={{
              borderRadius: "50%",
              width: 50,
              height: 50,
              padding: 15,
            }}
            backgroundColor={colors.ivory}
            handleClick={() => setOpenUploadPhotos(true)}
          >
            <EditSvg style={{ width: "100%", height: "100%" }} />
          </RoundButton>
        </div>
      </div>
      <div
        style={{
          padding: 15,
          display: "flex",
          flexDirection: "column",
          rowGap: 10,
          marginTop: -15,
          borderRadius: "20px 20px 0px 0px",
          zIndex: 1,
          backgroundColor: colors.black,
          position: "relative",
        }}
      >
        <div>
          <TextInput
            fontBold
            placeholder="Event Title"
            handleChange={setTitle}
            value={title}
            multiline
            inputProps={{
              style: {
                fontSize: 18,
              },
            }}
          />
        </div>
        <div style={{ marginTop: 20 }}>
          <TextInput
            type="number"
            placeholder="Number of Seats"
            handleChange={(value) => setCapacity(!value ? 0 : Number(value))}
            value={capacity === 0 ? "" : String(capacity)}
            inputProps={{
              min: 0,
            }}
            frontLogo={
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.1257 9.57797C12.7911 8.6793 13.1879 7.55859 13.1879 6.34273C13.1879 3.39648 10.8617 1 8.00171 1C5.14176 1 2.81548 3.39648 2.81548 6.34273C2.81548 7.55859 3.21232 8.6793 3.8777 9.57797C1.94655 10.741 0.513149 12.6916 0 15H3.52876C4.2523 13.1868 5.98333 11.9075 8 11.9075C10.0167 11.9075 11.7477 13.1868 12.4712 15H16C15.4869 12.6916 14.3138 10.913 12.381 9.75L12.1257 9.57797ZM8.00342 4.47137C9.00406 4.47137 9.81997 5.31013 9.81997 6.34273C9.81997 7.37533 9.00577 8.2141 8.00342 8.2141C7.00107 8.2141 6.18687 7.37533 6.18687 6.34273C6.18687 5.31013 7.00107 4.47137 8.00342 4.47137Z"
                  fill="#FFFAF6"
                />
              </svg>
            }
          />
        </div>
        <div>
          <TextInput
            type="number"
            placeholder="Price Per Person"
            handleChange={(value) => setPrice(!value ? 0 : Number(value))}
            value={price === 0 ? "" : String(price)}
            inputProps={{
              min: 0,
            }}
            frontLogo={
              <svg
                width="16"
                height="18"
                viewBox="0 0 16 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.902 17.9V15.62H9.486V17.9H6.902ZM6.902 2.719V0.438999H9.486V2.719H6.902ZM8.213 16.285C7.23133 16.285 6.35417 16.1045 5.5815 15.7435C4.80883 15.3762 4.17867 14.86 3.691 14.195C3.20333 13.5237 2.893 12.732 2.76 11.82L5.325 11.421C5.496 12.1683 5.85383 12.7637 6.3985 13.207C6.9495 13.6503 7.605 13.872 8.365 13.872C9.08067 13.872 9.67283 13.7042 10.1415 13.3685C10.6165 13.0328 10.854 12.618 10.854 12.124C10.854 11.7883 10.7495 11.5128 10.5405 11.2975C10.3378 11.0758 10.018 10.8953 9.581 10.756L6.199 9.711C4.24833 9.10933 3.273 7.91867 3.273 6.139C3.273 5.303 3.46933 4.57783 3.862 3.9635C4.261 3.34917 4.8215 2.87733 5.5435 2.548C6.27183 2.21233 7.13 2.04767 8.118 2.054C9.02367 2.06667 9.828 2.23133 10.531 2.548C11.234 2.85833 11.8198 3.31117 12.2885 3.9065C12.7635 4.4955 13.1087 5.21433 13.324 6.063L10.664 6.538C10.5753 6.13267 10.4107 5.778 10.17 5.474C9.92933 5.16367 9.6285 4.923 9.2675 4.752C8.91283 4.57467 8.517 4.47967 8.08 4.467C7.662 4.45433 7.282 4.5145 6.94 4.6475C6.60433 4.7805 6.33517 4.96733 6.1325 5.208C5.93617 5.44233 5.838 5.70833 5.838 6.006C5.838 6.33533 5.97733 6.62033 6.256 6.861C6.53467 7.09533 6.978 7.298 7.586 7.469L9.999 8.153C11.2087 8.50133 12.0795 8.97317 12.6115 9.5685C13.1498 10.1638 13.419 10.9523 13.419 11.934C13.419 12.7953 13.2005 13.5522 12.7635 14.2045C12.3265 14.8568 11.7153 15.3667 10.93 15.734C10.151 16.1013 9.24533 16.285 8.213 16.285Z"
                  fill="white"
                />
              </svg>
            }
          />
        </div>
        {price > 0 && (
          <div>
            <TextInput
              placeholder="Venmo: @"
              handleChange={(value) => {
                if (!value || !venmo) return setVenmo(value);
                setVenmo(value.substring("Venmo: @".length));
              }}
              value={!venmo ? "" : "Venmo: @" + venmo}
              frontLogo={
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.7203 0H1.36714C0.637499 0 0 0.525 0 1.24607V14.6286C0 15.3539 0.637499 16 1.36714 16H14.7164C15.45 16 16 15.35 16 14.6289V1.24607C16.0043 0.525 15.45 0 14.7203 0ZM8.7857 12.6786H5.08285L3.59821 3.80143L6.83928 3.49357L7.62499 9.81036C8.3582 8.61572 9.26356 6.73893 9.26356 5.45786C9.26356 4.75714 9.14356 4.27929 8.95606 3.88643L11.9071 3.28929C12.2486 3.85286 12.4021 4.43214 12.4021 5.16679C12.4018 7.50607 10.405 10.545 8.7857 12.6786Z"
                    fill="#FFFAF6"
                  />
                </svg>
              }
            />
          </div>
        )}
        <div>
          <DateTimeComponent
            date={date}
            startTime={startTime}
            endTime={endTime}
            handleSave={(newDate, newStartTime, newEndTime) => {
              setDate(newDate);
              setStartTime(newStartTime);
              setEndTime(newEndTime);
            }}
          />
        </div>
        <div>
          <LocationComponent
            address1={address1}
            address2={address2}
            city={city}
            state={state}
            zipcode={zipcode}
            handleSave={(
              newAddress1,
              newAddress2,
              newCity,
              newState,
              newZipcode
            ) => {
              setAddress1(newAddress1);
              setAddress2(newAddress2);
              setCity(newCity);
              setState(newState);
              setZipcode(newZipcode);
            }}
          />
        </div>
        <div style={{ marginTop: 20 }}>
          <TextInput
            placeholder="Description"
            handleChange={setDescription}
            multiline
            rows={5}
            value={description}
          />
        </div>
        <div
          style={{
            marginTop: 30,
          }}
        >
          <DragDropContext
            onDragEnd={(props) => {
              if (!props.destination) return;
              // swap index
              const temp = menus[props.source.index];
              menus[props.source.index] = menus[props.destination.index];
              menus[props.destination.index] = temp;
              setMenus([...menus]);
            }}
          >
            <Droppable droppableId="droppable">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {menus.map((menu, index) => (
                    <Draggable
                      draggableId={menu.id}
                      index={index}
                      key={menu.id}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                          }}
                        >
                          <MenuCardComponent
                            menu={menu}
                            index={index}
                            menus={menus}
                            setMenus={setMenus}
                            addMenu={() => {
                              setMenus([
                                ...menus,
                                {
                                  id: v4(),
                                  title: "",
                                  description: "",
                                  allergens: [],
                                },
                              ]);
                            }}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <div>
          <TextInput
            placeholder="House Rules (Optional)"
            handleChange={setHomeRule}
            multiline
            value={homeRule}
            rows={2}
          />
        </div>
        <div>
          <TextInput
            placeholder="Entry Instruction (Optional)"
            handleChange={setEntryInstruction}
            multiline
            value={entryInstruction}
            rows={2}
          />
        </div>
        <div style={{ marginTop: 15, textAlign: "center" }}>
          <RoundButton
            handleClick={handleSave}
            backgroundColor={colors.yellow}
            color={colors.black}
            disabled={disabledCondition}
          >
            {eventCode ? "Save Event" : "Publish Event"}
          </RoundButton>
        </div>
      </div>
    </>
  );
}

export default EventsDetailEdit;
