import {
  FilledInputProps,
  InputAdornment,
  InputBaseComponentProps,
  InputProps,
  OutlinedInputProps,
  SxProps,
  TextField,
  TextFieldVariants,
  Theme,
} from "@mui/material";
import React, { ReactNode } from "react";
import { convertToInternationalFormat, formatPhoneNumber } from "../util";
import { colors } from "../hooks/color";
import CustomImage from "../components/CustomImage";
import ErrorSvg from "../svgs/error.svg";

interface TextInputProps {
  label?: ReactNode;
  type?: React.HTMLInputTypeAttribute;
  handleChange?: (value: string) => void;
  multiline?: boolean;
  rows?: number;
  maxLength?: number;
  fullWidth?: boolean;
  defaultValue?: string;
  value?: string;
  placeholder?: string;
  name?: string;
  readOnly?: boolean;
  disabled?: boolean;
  inputProps?: InputBaseComponentProps;
  errorMessage?: string;
  sublabel?: ReactNode;
  sx?: SxProps<Theme>;
  maxRows?: number;
  InputProps?:
    | Partial<FilledInputProps>
    | Partial<OutlinedInputProps>
    | Partial<InputProps>;
  variant?: TextFieldVariants;
  dark?: boolean;
  handleClick?: () => void;
  frontLogo?: ReactNode;
  fontBold?: boolean;
}

function TextInput({
  label,
  type,
  handleChange,
  multiline,
  rows,
  maxLength,
  fullWidth = true,
  defaultValue,
  value,
  placeholder,
  name,
  readOnly,
  disabled,
  inputProps,
  errorMessage,
  sublabel,
  sx,
  maxRows,
  InputProps,
  variant = "outlined",
  dark,
  handleClick,
  frontLogo,
  fontBold,
}: TextInputProps) {
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <div
            style={{
              opacity: disabled ? 0.3 : 1,
              color: dark ? colors.black : colors.ivory,
            }}
          >
            {label}
          </div>
        </div>
        {sublabel && (
          <div
            style={{ opacity: 0.5, color: dark ? colors.black : colors.ivory }}
          >
            {sublabel}
          </div>
        )}
      </div>
      <div style={{ marginTop: 5 }}>
        <TextField
          onClick={handleClick}
          maxRows={maxRows}
          name={name}
          defaultValue={defaultValue}
          fullWidth={fullWidth}
          variant={variant}
          type={type}
          onChange={(e) => {
            if (handleChange) {
              handleChange(e.target.value);
            }
          }}
          multiline={multiline}
          rows={rows}
          inputProps={{
            maxLength,
            readOnly,
            ...inputProps,
          }}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          sx={{
            "& textarea": {
              "&::placeholder": {
                color: dark ? colors.black : "rgba(255, 250, 246, 0.3)",
              },
            },
            "& input": {
              "&::placeholder": {
                color: dark ? colors.black : "rgba(255, 250, 246, 0.3)",
              },
              fontFamily: "Manrope",
              fontSize: "16px",
              padding: "12px 20px",
              paddingLeft: frontLogo ? 0 : undefined,
            },
            "& .MuiInputBase-root": {
              fontWeight: fontBold ? 700 : undefined,
              fontFamily: "Manrope",
              overflow: "hidden",
              borderRadius: "30px",
              color: dark ? colors.black : colors.ivory,
              paddingLeft: frontLogo ? "20px" : 0,
              "&.MuiInputBase-multiline": {
                padding: "12px 20px",
                color: dark ? colors.black : colors.ivory,
                borderRadius: "20px",
              },
            },
            "& fieldset": {
              borderColor: `${
                dark ? colors.black : "rgba(255, 250, 246, 0.3)"
              } !important`,
            },
            "& .MuiFormHelperText-root": {
              marginLeft: 0,
              marginRight: 0,
            },
            "& input::-webkit-calendar-picker-indicator": {
              colorScheme: "dark",
            },
            ...sx,
          }}
          error={Boolean(errorMessage)}
          InputProps={{
            startAdornment: frontLogo ? (
              <InputAdornment position="start">{frontLogo}</InputAdornment>
            ) : undefined,
            ...InputProps,
          }}
        />
      </div>
      {errorMessage && (
        <div
          style={{
            marginTop: 10,
            display: "flex",
            color: colors.error,
            fontSize: 14,
            alignItems: "center",
          }}
        >
          <CustomImage src={ErrorSvg} width={20} height={20} />
          <div style={{ marginLeft: 5 }}>{errorMessage}</div>
        </div>
      )}
    </div>
  );
}

export function PhoneInput({
  label,
  handleChange,
  multiline,
  rows,
  maxLength,
  fullWidth = true,
  value,
  placeholder,
  name,
  readOnly,
  disabled,
  inputProps,
  errorMessage,
}: TextInputProps) {
  const handlePhoneNumberChange = (value: string) => {
    const formattedPhoneNumber = formatPhoneNumber(value);
    if (handleChange) {
      if (!formattedPhoneNumber) {
        handleChange("");
      } else {
        handleChange(convertToInternationalFormat(formattedPhoneNumber));
      }
    }
  };

  return (
    <TextInput
      label={label}
      value={value ? formatPhoneNumber(value.slice(2)) : ""}
      handleChange={handlePhoneNumberChange}
      placeholder={placeholder}
      type="text"
      inputProps={{
        inputMode: "numeric",
        ...inputProps,
      }}
      multiline={multiline}
      rows={rows}
      maxLength={maxLength}
      fullWidth={fullWidth}
      name={name}
      readOnly={readOnly}
      disabled={disabled}
      errorMessage={errorMessage}
    />
  );
}

export default TextInput;
