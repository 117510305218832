import { useCallback, useContext, useEffect, useState } from "react";
import { fetchData, getCredential, removeCredential } from "../api";
import { GlobalContext } from "../App";
import { User } from "../interfaces";

export function useUser() {
  const { user } = useContext(GlobalContext);
  return user;
}

export function useSelf() {
  const [self, setSelf] = useState<User | null>(null);

  const refresh = useCallback(() => {
    const credential = getCredential();
    if (!credential) {
      return new Promise((resolve) => resolve(setSelf(null)));
    }
    return fetchData<User>("GET", "/self").then(setSelf);
  }, []);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return { self, refresh };
}

export function useUsers() {
  const [users, setUsers] = useState<User[] | null>(null);

  const refresh = useCallback(() => {
    return fetchData<User[]>("GET", "/users").then(setUsers);
  }, []);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return { users, refresh };
}

interface PutUserProfileBody {
  nickname: string;
}

export function useUserHooks() {
  const { refreshUser } = useContext(GlobalContext);

  const signout = () => {
    removeCredential();
    return refreshUser();
  };

  const putUserProfile = (body: PutUserProfileBody) => {
    return fetchData("PUT", `/users`, body).then(refreshUser);
  };

  const putUserImage = (image: string) => {
    return fetchData("PATCH", `/users/image`, { image });
  };

  const putUserCover = (image: string) => {
    return fetchData("PATCH", `/users/cover`, { image });
  };

  const deleteUser = () => {
    return fetchData("DELETE", `/users`);
  };

  const patchUserIdentity = (identity_image: string) => {
    return fetchData("PATCH", `/users/identity_image`, { identity_image }).then(
      refreshUser
    );
  };

  return {
    signout,
    putUserCover,
    putUserProfile,
    putUserImage,
    deleteUser,
    patchUserIdentity,
  };
}
