import React from "react";
import Backward from "./Backward";

interface HeaderProps {
  handleBack?: () => void;
  label?: string;
  style?: React.CSSProperties;
  backgroundGradient?: boolean;
}

function Header({ handleBack, label, style, backgroundGradient }: HeaderProps) {
  return (
    <div
      style={{
        position: "sticky",
        top: 0,
        zIndex: 10,
        background: backgroundGradient
          ? "linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%)"
          : undefined,
        ...style,
      }}
    >
      <div
        style={{
          height: 55,
          fontSize: 24,
          fontWeight: 600,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        {label || ""}
        {handleBack && (
          <Backward
            handleClick={handleBack}
            style={{
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              left: 15,
              backgroundColor: "transparent",
              borderRadius: "none",
            }}
          />
        )}
      </div>
    </div>
  );
}

export default Header;
