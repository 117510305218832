import React, {
  Fragment,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUser } from "../../hooks/user";
import Backward from "../../components/Backward";
import CustomSwiper from "../../components/CustomSwiper";
import CustomImage from "../../components/CustomImage";
import Avatar, { AvatarName } from "../../components/Avatar";
import Horizontal from "../../components/Horizontal";
import { colors } from "../../hooks/color";
import CustomDrawer from "../../components/CustomDrawer";
import TextInput from "../../inputs/TextInput";
import { ReactComponent as ProfileSvg } from "../../svgs/profile.svg";
import { ReactComponent as DollarSvg } from "../../svgs/dollar.svg";
import { ReactComponent as CalendarSvg } from "../../svgs/calendar.svg";
import { ReactComponent as LocationSvg } from "../../svgs/location.svg";
import { ReactComponent as ShareSvg } from "../../svgs/share.svg";
import RoundButton from "../../components/RoundButton";
import CustomDialog from "../../components/CustomDialog";
import YellowComponent from "../../components/YellowComponent";
import { ReactComponent as WarningSvg } from "../../svgs/warning.svg";
import { ReactComponent as AddSvg } from "../../svgs/add.svg";
import { ReactComponent as SubtractSvg } from "../../svgs/subtract.svg";
import { ReactComponent as TablySvg } from "../../svgs/tably.svg";
import {
  EventDetail,
  EventDetailUserEvent,
  EventMenuDetail,
} from "../../interfaces";
import { fetchData } from "../../api";
import TimeUtil from "../../utils/time";
import ReadMore from "../../components/ReadMore";
import { EventDetailSelfStatus, EventStatus } from "../../enums";
import { Circle, GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import Config from "../../config";
import AccordionView from "../../components/AccordionView";
import { SwiperClass } from "swiper/react";
import { Helmet } from "react-helmet";
import VerificationWrapper from "../../components/VerificationWrapper";
import { Dialog } from "@mui/material";

function ShareComponent() {
  const { eventDetail } = useContext(EventDetailContext);
  const sharable = Boolean(navigator.share);
  if (!sharable) return null;
  return (
    <>
      <div
        style={{
          width: 32,
          height: 32,
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: 15,
          right: 15,
          cursor: "pointer",
          zIndex: 30,
          backgroundColor: colors.ivory,
          color: colors.black,
        }}
        onClick={() => {
          navigator
            .share({
              title: eventDetail.title,
              text: eventDetail.description,
              url: window.location.href,
            })
            .catch(console.log);
        }}
      >
        <ShareSvg />
      </div>
    </>
  );
}

function EventImageComponent() {
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const [current, setCurrent] = useState<number>(0);
  const { eventDetail } = useContext(EventDetailContext);

  useEffect(() => {
    if (!swiper) return;
    swiper.on("activeIndexChange", (swiper: SwiperClass) =>
      setCurrent(swiper.activeIndex)
    );
  }, [swiper]);

  return (
    <div style={{ position: "relative" }}>
      <ShareComponent />
      {eventDetail.images.length === 0 && <Banner image="" />}
      {eventDetail.images.length > 0 && (
        <CustomSwiper
          slides={eventDetail.images.map((image, index) => (
            <Banner image={image} key={index} />
          ))}
          setSwiper={setSwiper}
        />
      )}
      {eventDetail.images.length > 0 && (
        <div style={{ zIndex: 1, position: "absolute", bottom: 30, right: 30 }}>
          <RoundButton
            style={{
              opacity: 0.8,
            }}
          >
            {current + 1}/{eventDetail.images.length}
          </RoundButton>
        </div>
      )}
    </div>
  );
}

interface RejectComponentProps {
  reject: string;
  reject_detail: string;
}

export function RejectComponent({
  reject,
  reject_detail,
}: RejectComponentProps) {
  return (
    <div style={{ color: colors.red }}>
      <div style={{ fontWeight: 700 }}>Reject Reason</div>
      <div style={{ marginTop: 15 }}>
        {reject !== "Other" && reject}
        {reject === "Other" && reject_detail}
      </div>
    </div>
  );
}

interface GoogleMapComponentProps {
  eventDetail: EventDetail;
}

function GoogleMapComponent({ eventDetail }: GoogleMapComponentProps) {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: Config.getGoogleMapsApiKey(),
  });

  if (!isLoaded || !eventDetail.lat_lng) return null;

  return (
    <GoogleMap
      mapContainerStyle={{
        height: 200,
        borderRadius: 20,
      }}
      center={{
        lat: eventDetail.lat_lng.lat,
        lng: eventDetail.lat_lng.lng,
      }}
      zoom={13}
      options={{
        fullscreenControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        zoomControl: false,
      }}
    >
      <Circle
        options={{
          strokeColor: colors.red,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: colors.red,
          fillOpacity: 0.35,
          center: {
            lat: eventDetail.lat_lng.lat,
            lng: eventDetail.lat_lng.lng,
          },
          radius: 600,
        }}
      />
    </GoogleMap>
  );
}

function HostInfoComponent() {
  const { eventDetail } = useContext(EventDetailContext);

  return (
    <>
      <div
        style={{
          display: "flex",
          columnGap: 20,
          alignItems: "center",
        }}
      >
        <div style={{ position: "relative", display: "flex" }}>
          <Avatar
            src={eventDetail.host_user.meta.image}
            width={50}
            height={50}
            style={{
              border: `2px solid ${colors.ivory}`,
            }}
          />
          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              display: "flex",
            }}
          >
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="11" cy="11" r="11" fill="#FCFF76" />
              <path
                d="M6.99756 15.8301H15.0026V17.1051H6.99756V15.8301Z"
                fill="#242525"
              />
              <path
                d="M15.2527 6.84503C15.0171 6.84503 14.7815 6.87629 14.5521 6.93691C14.4446 6.96504 14.3333 6.92004 14.2752 6.82628C13.5702 5.68253 12.3457 5 11.0001 5C9.65503 5 8.43062 5.68251 7.72501 6.82628C7.66751 6.92003 7.55501 6.96503 7.44813 6.93691C7.21875 6.87628 6.98312 6.84503 6.74749 6.84503C5.23254 6.84503 4 8.07879 4 9.59519C4 11.1116 5.23252 12.3427 6.74749 12.3427C6.88561 12.3427 6.99749 12.4546 6.99749 12.5927V15.3302H15.0025V12.5927C15.0025 12.4546 15.1144 12.3427 15.2525 12.3427C16.7675 12.3427 18 11.1102 18 9.59519C18 8.08022 16.7675 6.84503 15.2525 6.84503H15.2527Z"
                fill="#242525"
              />
            </svg>
          </div>
        </div>
        <div>Hosted by {eventDetail.host_user.meta.nickname}</div>
      </div>
    </>
  );
}

interface PrefixMenuProps {
  menus: EventMenuDetail[];
}

function PrefixMenu({ menus }: PrefixMenuProps) {
  return (
    <div
      style={{
        backgroundColor: colors.blue,
        borderRadius: 20,
        padding: 20,
        color: colors.black,
      }}
    >
      <div style={{ fontSize: 24, fontWeight: 700 }}>Menu</div>
      <div>
        {menus.map((menu, index) => (
          <Fragment key={index}>
            {index > 0 && (
              <Horizontal
                marginTop={15}
                marginBottom={15}
                horizontalColor={colors.black}
              />
            )}
            <div
              style={{
                marginTop: 10,
                fontWeight: 700,
                fontSize: 20,
              }}
            >
              {menu.title}
            </div>
            <div style={{ marginTop: 10 }}>{menu.description}</div>
            {menu.allergens && menu.allergens.length > 0 && (
              <div
                style={{
                  display: "flex",
                  columnGap: 5,
                  fontSize: 14,
                  alignItems: "center",
                  marginTop: 10,
                }}
              >
                <WarningSvg />
                <div style={{ fontWeight: 700 }}>
                  {menu.allergens.join(", ")}
                </div>
              </div>
            )}
          </Fragment>
        ))}
      </div>
    </div>
  );
}

interface EventIntroductionProps {
  description: string;
}

function EventIntroduction({ description }: EventIntroductionProps) {
  return <ReadMore>{description}</ReadMore>;
}

interface BannerProps {
  image?: string;
}

function Banner({ image }: BannerProps) {
  return <CustomImage height={350} src={image} />;
}

interface LocationInfoProps {
  capacity: number;
  date: string;
  startTime: string;
  endTime: string;
  address: string;
  totalReserved: number;
  status: EventStatus;
  event: EventDetail;
}

function LocationInfo({
  capacity,
  date,
  startTime,
  endTime,
  address,
  totalReserved,
  status,
  event,
}: LocationInfoProps) {
  return (
    <YellowComponent>
      <div style={{ display: "flex", flexDirection: "column", rowGap: 10 }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ProfileSvg
            style={{
              width: 20,
            }}
            fill={colors.black}
          />
          <div style={{ marginLeft: 10 }}>
            {status !== EventStatus.closed &&
              `${totalReserved}/${capacity} Seats Filled`}
            {status === EventStatus.closed && `${totalReserved} Attended`}
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <DollarSvg
            style={{
              width: 20,
            }}
            fill={colors.black}
          />
          <div style={{ marginLeft: 10 }}>
            ${event.price.toFixed(2)} Per Person
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <CalendarSvg
            style={{
              width: 20,
            }}
            fill={colors.black}
          />
          <div style={{ marginLeft: 10 }}>
            {TimeUtil.formatDateStartEndTime(date, startTime, endTime)}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <LocationSvg
            style={{
              width: 20,
            }}
            fill={colors.black}
          />
          <div
            style={{
              marginLeft: 10,
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() => {
              const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                address
              )}`;
              window.open(googleMapsUrl, "_blank", "noopener,noreferrer");
            }}
          >
            {address}
          </div>
        </div>
      </div>
    </YellowComponent>
  );
}

// function AttendingList() {
//   const { eventDetail, refresh } = useContext(EventDetailContext);
//   const [open, setOpen] = useState<boolean>(false);
//   const [guestCancel, setGuestCancel] = useState<string>("");
//   const [guestCancelDetail, setGuestCancelDetail] = useState<string>("");
//   const user = useUser();

//   const handleRemoveReservation = (reservationId: number) => {
//     fetchData("DELETE", `/reservations/${reservationId}`, {
//       guest_cancel: guestCancel,
//       guest_cancel_detail: guestCancelDetail,
//     })
//       .then(refresh)
//       .then(() => setOpen(false));
//   };

//   const disabledCondition = !(
//     Boolean(guestCancel) &&
//     (guestCancel !== "Other" || Boolean(guestCancelDetail))
//   );

//   return (
//     <div>
//       <div
//         style={{
//           fontSize: 14,
//           fontWeight: 700,
//         }}
//       >
//         People Attending
//       </div>
//       <div
//         style={{
//           display: "flex",
//           columnGap: 25,
//           overflow: "auto",
//         }}
//       >
//         {eventDetail.reservations.map((reservation, index) => (
//           <Fragment key={index}>
//             <UserAvatar
//               nickname={reservation.nickname}
//               image={reservation.image}
//               partySize={reservation.party_size}
//               userId={reservation.user_id}
//               footer={
//                 <>
//                   {eventDetail.status === EventStatus.published &&
//                     eventDetail.host.id === user?.host?.id && (
//                       <div style={{ textAlign: "center" }}>
//                         <RoundButton
//                           backgroundColor={colors.red}
//                           handleClick={() => setOpen(true)}
//                         >
//                           Remove from This Event
//                         </RoundButton>
//                       </div>
//                     )}
//                 </>
//               }
//             />
//             <CustomDialog open={open} handleClose={() => setOpen(false)}>
//               <div style={{ marginTop: 30, fontSize: 24, fontWeight: 600 }}>
//                 Are you sure you want to remove this guest from your event?
//               </div>
//               <div style={{ marginTop: 30 }}>
//                 <SelectInput
//                   label="Select Your Reason"
//                   value={guestCancel}
//                   items={guestCancelTypes.map((guestCancelType) => ({
//                     label: guestCancelType,
//                     value: guestCancelType,
//                   }))}
//                   handleChange={setGuestCancel}
//                 />
//               </div>
//               {guestCancel === "Other" && (
//                 <div style={{ marginTop: 30 }}>
//                   <TextInput
//                     label="Details"
//                     multiline
//                     rows={5}
//                     handleChange={setGuestCancelDetail}
//                     defaultValue={guestCancelDetail}
//                   />
//                 </div>
//               )}
//               <div style={{ marginTop: 30, textAlign: "center" }}>
//                 <RoundButton
//                   color={colors.ivory}
//                   backgroundColor={colors.red}
//                   disabled={disabledCondition}
//                   handleClick={() =>
//                     handleRemoveReservation(reservation.reservation_id)
//                   }
//                 >
//                   Remove
//                 </RoundButton>
//               </div>
//             </CustomDialog>
//           </Fragment>
//         ))}
//       </div>
//     </div>
//   );
// }

enum EventDetailType {
  info = "info",
  checkout = "checkout",
  confirmation = "confirmation",
  refund = "refund",
}

interface EventDetailContextProps {
  eventDetail: EventDetail;
}

const EventDetailContext = createContext({} as EventDetailContextProps);

function EventsDetail() {
  const params = useParams();
  const [eventDetail, setEventDetail] = useState<EventDetail | null>(null);
  const [type, setType] = useState<EventDetailType>(EventDetailType.info);
  const navigate = useNavigate();
  const [partySize, setPartySize] = useState<number>(1);
  const [render, setRender] = useState<number>(0);
  const user = useUser();
  const [openComment, setOpenComment] = useState<boolean>(false);
  const [openFullList, setOpenFullList] = useState<boolean>(false);
  const [removeUserEvent, setRemoveUserEvent] =
    useState<EventDetailUserEvent | null>(null);
  const [comment, setComment] = useState<string>("");
  const [fullListSelect, setFullListSelect] = useState<EventDetailSelfStatus>(
    EventDetailSelfStatus.going
  );
  const [openOptions, setOpenOptions] = useState<boolean>(false);

  useEffect(() => {
    fetchData<EventDetail>(
      "GET",
      `/events/detail?event_code=${params.eventCode}`
    ).then(setEventDetail);
  }, [params, render]);

  const refresh = () => setRender((render) => render + 1);

  if (!eventDetail) return null;

  const createNote = () => {
    if (!user) return "";
    return `Tably event: ${eventDetail.title}, Email: ${user.meta.email}, Name: ${user.meta.nickname} ${user.meta.lastname}`;
  };

  const handlePay = () => {
    if (!user) return;
    // check availability first
    if (eventDetail.reserved + partySize > eventDetail.capacity) {
      alert("can't reserve, over the limit");
      return;
    }
    if (eventDetail.price === 0) {
      return handleGoing();
    }
    window.open(
      `https://venmo.com/${eventDetail.venmo}?txn=pay&amount=${
        partySize * eventDetail.price
      }&note=${encodeURI(createNote())}`,
      "_blank"
    );
    setType(EventDetailType.confirmation);
  };

  const handleGoing = () => {
    fetchData("PATCH", `/events/${params.eventCode}/going`, {
      party_size: partySize,
    })
      .then(refresh)
      .then(() => setType(EventDetailType.info));
  };

  const handleNotGoing = () => {
    fetchData("PATCH", `/events/${params.eventCode}/not_going`)
      .then(refresh)
      .then(() => setType(EventDetailType.info));
  };

  const handlePending = () => {
    fetchData("PATCH", `/events/${params.eventCode}/pending`)
      .then(refresh)
      .then(() => setType(EventDetailType.info));
  };

  const handleRequestRefund = () => {
    if (!user) return;
    if (eventDetail.price === 0) {
      return handleNotGoing();
    }
    window.open(
      `https://venmo.com/${eventDetail.venmo}?txn=charge&amount=${
        eventDetail.self_reserved * eventDetail.price
      }&note=${encodeURI(createNote())}`,
      "_blank"
    );
  };

  const handleSubmit = () => {
    if (!comment) return;
    fetchData("POST", `/events/${params.eventCode}/comment`, {
      comment,
    })
      .then(refresh)
      .then(() => {
        setOpenComment(false);
        setComment("");
      });
  };

  const handleRemove = () => {
    if (!removeUserEvent) return;
    fetchData("DELETE", `/users/events/${removeUserEvent.id}`)
      .then(refresh)
      .then(() => setRemoveUserEvent(null));
  };

  return (
    <EventDetailContext.Provider
      value={{
        eventDetail,
      }}
    >
      <Helmet>
        <title>{eventDetail.title}</title>
        <meta property="og:title" content={eventDetail.title} />
        <meta property="og:description" content={eventDetail.description} />
        <meta property="og:image" content={eventDetail.images[0] || ""} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <Backward
        type="inverted"
        handleClick={() => {
          if (type === EventDetailType.refund)
            return setType(EventDetailType.info);
          if (type === EventDetailType.confirmation)
            return setType(EventDetailType.checkout);
          if (type === EventDetailType.checkout)
            return setType(EventDetailType.info);
          return navigate("/");
        }}
      />
      {type === EventDetailType.confirmation && (
        <div style={{ padding: 15 }}>
          <div
            style={{
              marginTop: 50,
              textAlign: "center",
              fontSize: 24,
              fontWeight: 600,
            }}
          >
            Did you pay?
          </div>
          <div
            style={{
              marginTop: 30,
              display: "flex",
              columnGap: 10,
              justifyContent: "center",
            }}
          >
            <RoundButton
              color={colors.black}
              backgroundColor={colors.yellow}
              handleClick={handleGoing}
            >
              Yes
            </RoundButton>
            <RoundButton
              color={colors.black}
              backgroundColor={colors.yellow}
              handleClick={handlePay}
            >
              Pay Now
            </RoundButton>
          </div>
        </div>
      )}
      {type === EventDetailType.refund && (
        <div style={{ padding: 15 }}>
          <div
            style={{
              marginTop: 50,
              textAlign: "center",
              fontSize: 24,
              fontWeight: 600,
            }}
          >
            Are you sure you want to change to “Not Going”?
          </div>
          <div
            style={{
              marginTop: 30,
              display: "flex",
              columnGap: 10,
              justifyContent: "center",
            }}
          >
            {eventDetail.price === 0 && (
              <RoundButton
                color={colors.black}
                backgroundColor={colors.yellow}
                handleClick={handleRequestRefund}
              >
                Yes, Change to “Not Going”
              </RoundButton>
            )}
            {eventDetail.price > 0 && (
              <>
                <RoundButton
                  color={colors.black}
                  backgroundColor={colors.yellow}
                  handleClick={handleRequestRefund}
                >
                  Yes, Request Refund
                </RoundButton>
                <RoundButton
                  color={colors.black}
                  backgroundColor={colors.yellow}
                  handleClick={handleNotGoing}
                >
                  Already Requested
                </RoundButton>
              </>
            )}
          </div>
        </div>
      )}
      {(type === EventDetailType.info || type === EventDetailType.checkout) && (
        <>
          <EventImageComponent />
          <div
            style={{
              padding: 15,
              marginTop: -15,
              borderRadius: "20px 20px 0px 0px",
              position: "relative",
              zIndex: 5,
              backgroundColor: colors.black,
            }}
          >
            <div style={{ fontSize: 24 }}>{eventDetail.title}</div>
            <div style={{ marginTop: 30 }}>
              <LocationInfo
                event={eventDetail}
                capacity={eventDetail.capacity}
                date={eventDetail.date}
                startTime={eventDetail.start_time}
                endTime={eventDetail.end_time}
                address={eventDetail.address}
                totalReserved={eventDetail.reserved}
                status={eventDetail.status}
              />
            </div>
            {type === EventDetailType.info && (
              <>
                <Horizontal marginTop={15} marginBottom={15} opacity={0.5} />
                <HostInfoComponent />
                <Horizontal marginTop={15} marginBottom={15} opacity={0.5} />
                <EventIntroduction description={eventDetail.description} />
                <div style={{ marginTop: 30 }}>
                  <PrefixMenu menus={eventDetail.menus} />
                </div>
                <div style={{ marginTop: 30 }}>
                  <GoogleMapComponent eventDetail={eventDetail} />
                </div>
                {Boolean(eventDetail.home_rule) && (
                  <>
                    <Horizontal
                      marginTop={15}
                      marginBottom={15}
                      opacity={0.5}
                    />
                    <AccordionView
                      label="House Rules"
                      detail={eventDetail.home_rule}
                    />
                  </>
                )}
                {Boolean(eventDetail.entry_instruction) && (
                  <>
                    <Horizontal
                      marginTop={15}
                      marginBottom={15}
                      opacity={0.5}
                    />
                    <AccordionView
                      label="Entry Instruction"
                      detail={eventDetail.entry_instruction}
                    />
                  </>
                )}
                <Horizontal marginTop={15} marginBottom={15} opacity={0.5} />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontWeight: 700,
                    }}
                  >
                    People Attending
                  </div>
                  <RoundButton
                    backgroundColor={colors.ivory}
                    color={colors.black}
                    style={{
                      visibility:
                        eventDetail.self_status ===
                        EventDetailSelfStatus.stranger
                          ? "hidden"
                          : "visible",
                    }}
                    handleClick={() => setOpenFullList(true)}
                  >
                    Full List
                  </RoundButton>
                </div>
                {eventDetail.user_events.length > 0 && (
                  <div
                    style={{
                      paddingTop: 15,
                      display: "flex",
                      columnGap: 10,
                      filter:
                        eventDetail.self_status ===
                        EventDetailSelfStatus.stranger
                          ? "blur(4px)"
                          : undefined,
                      userSelect:
                        eventDetail.self_status ===
                        EventDetailSelfStatus.stranger
                          ? "none"
                          : undefined,
                      overflowX:
                        eventDetail.self_status ===
                        EventDetailSelfStatus.stranger
                          ? "hidden"
                          : "auto",
                      overflowY: "hidden",
                    }}
                  >
                    {eventDetail.user_events
                      .filter(
                        (user_event) =>
                          user_event.status === EventDetailSelfStatus.going
                      )
                      .map((user_event) => (
                        <div
                          key={user_event.id}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <AvatarName
                            width={40}
                            label={user_event.nickname}
                            pops={user_event.party_size}
                            src={user_event.image}
                          />
                        </div>
                      ))}
                  </div>
                )}
                <Horizontal marginTop={15} marginBottom={15} opacity={0.5} />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontWeight: 700,
                    }}
                  >
                    Activity
                  </div>
                  <RoundButton
                    backgroundColor={colors.yellow}
                    color={colors.black}
                    handleClick={() => setOpenComment(true)}
                    style={{
                      visibility:
                        eventDetail.self_status ===
                          EventDetailSelfStatus.stranger || openComment
                          ? "hidden"
                          : "visible",
                    }}
                  >
                    Comment
                  </RoundButton>
                </div>
                {openComment && (
                  <div style={{ marginTop: 15 }}>
                    <TextInput
                      label="Comment"
                      multiline
                      rows={5}
                      handleChange={setComment}
                    />
                    <div
                      style={{
                        marginTop: 30,
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <RoundButton
                        color={colors.black}
                        backgroundColor={colors.yellow}
                        handleClick={handleSubmit}
                      >
                        Submit
                      </RoundButton>
                      <RoundButton
                        handleClick={() => setOpenComment(false)}
                        color={colors.black}
                        backgroundColor={colors.ivory}
                      >
                        Cancel
                      </RoundButton>
                    </div>
                  </div>
                )}
                {eventDetail.comments.length > 0 && (
                  <div
                    style={{
                      marginTop: 15,
                      overflow:
                        eventDetail.self_status ===
                        EventDetailSelfStatus.stranger
                          ? "hidden"
                          : "auto",
                      maxHeight: 500,
                      display: "flex",
                      flexDirection: "column",
                      rowGap: 15,
                      filter:
                        eventDetail.self_status ===
                        EventDetailSelfStatus.stranger
                          ? "blur(4px)"
                          : undefined,
                      userSelect:
                        eventDetail.self_status ===
                        EventDetailSelfStatus.stranger
                          ? "none"
                          : undefined,
                    }}
                  >
                    {eventDetail.comments.map((comment, index) => (
                      <div
                        key={index}
                        style={{ display: "flex", columnGap: 10 }}
                      >
                        <Avatar width={40} height={40} src={comment.image} />
                        <div style={{ fontSize: 14 }}>
                          <div style={{ fontWeight: 700 }}>
                            {comment.nickname}
                          </div>
                          <div style={{ marginTop: 5, whiteSpace: "pre-line" }}>
                            {comment.comment}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {!eventDetail.is_past && (
                  <>
                    {eventDetail.self_status !== EventDetailSelfStatus.host && (
                      <div
                        style={{
                          paddingTop: 30,
                          position: "sticky",
                          left: 15,
                          right: 15,
                          bottom: 0,
                          textAlign: "center",
                          background:
                            "linear-gradient(0deg, #242525 0%, rgba(36, 37, 37, 0) 100%)",
                        }}
                      >
                        <div style={{ paddingBottom: 30 }}>
                          {eventDetail.self_status !==
                            EventDetailSelfStatus.stranger && (
                            <>
                              <VerificationWrapper
                                handleClick={() => setOpenOptions(true)}
                              >
                                <RoundButton
                                  color={colors.black}
                                  backgroundColor={colors.yellow}
                                >
                                  {eventDetail.self_status ===
                                    EventDetailSelfStatus.going && "Going"}
                                  {eventDetail.self_status ===
                                    EventDetailSelfStatus.not_going &&
                                    "Not Going"}
                                  {eventDetail.self_status ===
                                    EventDetailSelfStatus.pending && "Maybe"}
                                </RoundButton>
                              </VerificationWrapper>
                              <Dialog
                                open={openOptions}
                                onClose={() => setOpenOptions(false)}
                                PaperProps={{
                                  style: {
                                    borderRadius: "20px",
                                    backgroundColor: colors.yellow,
                                    color: colors.black,
                                    padding: 30,
                                    position: "absolute",
                                    bottom: 100,
                                  },
                                }}
                                fullWidth
                              >
                                <div
                                  style={{
                                    fontSize: 15,
                                    fontWeight: 700,
                                    display: "flex",
                                    flexDirection: "column",
                                    rowGap: 30,
                                  }}
                                >
                                  <div
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    Change RSVP Status
                                  </div>
                                  {eventDetail.self_status !==
                                    EventDetailSelfStatus.going && (
                                    <VerificationWrapper
                                      handleClick={() => {
                                        setType(EventDetailType.checkout);
                                        setOpenOptions(false);
                                      }}
                                      style={{ justifyContent: "center" }}
                                    >
                                      <div style={{ opacity: 0.5 }}>Going</div>
                                    </VerificationWrapper>
                                  )}
                                  {eventDetail.self_status ===
                                    EventDetailSelfStatus.not_going && (
                                    <VerificationWrapper
                                      handleClick={() => {
                                        handlePending();
                                        setOpenOptions(false);
                                      }}
                                      style={{ justifyContent: "center" }}
                                    >
                                      <div style={{ opacity: 0.5 }}>Maybe</div>
                                    </VerificationWrapper>
                                  )}
                                  {eventDetail.self_status !==
                                    EventDetailSelfStatus.not_going && (
                                    <VerificationWrapper
                                      handleClick={() => {
                                        // if it was paid, then ask for refund
                                        // else just make not going
                                        if (
                                          eventDetail.self_status ===
                                          EventDetailSelfStatus.going
                                        ) {
                                          setType(EventDetailType.refund);
                                        } else {
                                          handleNotGoing();
                                        }
                                        setOpenOptions(false);
                                      }}
                                      style={{ justifyContent: "center" }}
                                    >
                                      <div style={{ opacity: 0.5 }}>
                                        Not Going
                                      </div>
                                    </VerificationWrapper>
                                  )}
                                </div>
                              </Dialog>
                            </>
                          )}
                          {eventDetail.self_status ===
                            EventDetailSelfStatus.stranger && (
                            <div
                              style={{
                                display: "flex",
                                columnGap: 10,
                                filter:
                                  "drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25))",
                                justifyContent: "center",
                              }}
                            >
                              <VerificationWrapper
                                handleClick={() =>
                                  setType(EventDetailType.checkout)
                                }
                              >
                                <RoundButton
                                  color={colors.black}
                                  backgroundColor={colors.grey}
                                >
                                  Going
                                </RoundButton>
                              </VerificationWrapper>
                              <VerificationWrapper handleClick={handlePending}>
                                <RoundButton
                                  color={colors.black}
                                  backgroundColor={colors.grey}
                                >
                                  Maybe
                                </RoundButton>
                              </VerificationWrapper>
                              <VerificationWrapper handleClick={handleNotGoing}>
                                <RoundButton
                                  color={colors.black}
                                  backgroundColor={colors.grey}
                                >
                                  Not Going
                                </RoundButton>
                              </VerificationWrapper>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {eventDetail.self_status === EventDetailSelfStatus.host && (
                      <>
                        <Horizontal
                          marginTop={15}
                          marginBottom={15}
                          opacity={0.5}
                        />
                        <div
                          style={{
                            color: colors.yellow,
                            textDecoration: "underline",
                            cursor: "pointer",
                            fontWeight: 700,
                            textAlign: "center",
                          }}
                          onClick={() =>
                            navigate(`/events/${eventDetail.event_code}/edit`)
                          }
                        >
                          Edit Event
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
            {type === EventDetailType.checkout && (
              <>
                <div style={{ marginTop: 50 }}>Number of Seats</div>
                <Horizontal marginTop={15} marginBottom={15} opacity={0.5} />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    columnGap: 40,
                  }}
                >
                  <SubtractSvg
                    style={{
                      cursor: "pointer",
                    }}
                    fill={colors.ivory}
                    stroke={colors.ivory}
                    onClick={() => {
                      if (partySize === 1) return;
                      setPartySize(partySize - 1);
                    }}
                  />
                  <div style={{ display: "flex", position: "relative" }}>
                    <TablySvg fill={colors.ivory} />
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: "50%",
                        transform: "translate(-50%,-50%)",
                        fontSize: 24,
                        fontWeight: 700,
                      }}
                    >
                      {partySize}
                    </div>
                  </div>
                  <AddSvg
                    style={{
                      cursor: "pointer",
                    }}
                    fill={colors.ivory}
                    stroke={colors.ivory}
                    onClick={() => {
                      if (
                        partySize === 99 ||
                        eventDetail.reserved + partySize >= eventDetail.capacity
                      )
                        return;
                      setPartySize(partySize + 1);
                    }}
                  />
                </div>
                <div style={{ marginLeft: -15, marginRight: -15 }}>
                  <Horizontal marginTop={15} marginBottom={15} opacity={0.5} />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    Total: ${(partySize * eventDetail.price).toFixed(2)}
                  </div>
                  <RoundButton
                    color={colors.black}
                    handleClick={handlePay}
                    backgroundColor={colors.yellow}
                  >
                    {eventDetail.price === 0 ? "Book Now" : "Pay Now"}
                  </RoundButton>
                </div>
              </>
            )}
          </div>
        </>
      )}
      <CustomDrawer
        open={openFullList}
        handleClose={() => setOpenFullList(false)}
      >
        <div style={{ display: "flex", fontSize: 14 }}>
          <div
            style={{
              flex: 1,
              textAlign: "center",
              cursor: "pointer",
              fontWeight:
                fullListSelect === EventDetailSelfStatus.going
                  ? 800
                  : undefined,
            }}
            onClick={() => setFullListSelect(EventDetailSelfStatus.going)}
          >
            Going (
            {eventDetail.user_events
              .filter(
                (user_event) =>
                  user_event.status === EventDetailSelfStatus.going
              )
              .reduce((a, b) => a + b.party_size, 0)}
            )
          </div>
          <div
            style={{
              flex: 1,
              textAlign: "center",
              cursor: "pointer",
              fontWeight:
                fullListSelect === EventDetailSelfStatus.not_going
                  ? 800
                  : undefined,
            }}
            onClick={() => setFullListSelect(EventDetailSelfStatus.not_going)}
          >
            Not Going (
            {
              eventDetail.user_events.filter(
                (user_event) =>
                  user_event.status === EventDetailSelfStatus.not_going
              ).length
            }
            )
          </div>
          <div
            style={{
              flex: 1,
              textAlign: "center",
              cursor: "pointer",
              fontWeight:
                fullListSelect === EventDetailSelfStatus.pending
                  ? 800
                  : undefined,
            }}
            onClick={() => setFullListSelect(EventDetailSelfStatus.pending)}
          >
            Maybe (
            {
              eventDetail.user_events.filter(
                (user_event) =>
                  user_event.status === EventDetailSelfStatus.pending
              ).length
            }
            )
          </div>
        </div>
        <Horizontal
          marginTop={15}
          marginBottom={15}
          horizontalColor={colors.black}
        />
        <div style={{ display: "flex", flexDirection: "column", rowGap: 10 }}>
          {eventDetail.user_events
            .filter((user_event) => user_event.status === fullListSelect)
            .map((user_event) => (
              <div
                key={user_event.id}
                style={{
                  display: "flex",
                  columnGap: 10,
                  alignItems: "center",
                }}
              >
                <Avatar width={40} height={40} src={user_event.image} />
                <div>
                  {fullListSelect === EventDetailSelfStatus.going && (
                    <>
                      {user_event.nickname} ({user_event.party_size})
                    </>
                  )}
                  {fullListSelect !== EventDetailSelfStatus.going && (
                    <>{user_event.nickname}</>
                  )}
                </div>
                {eventDetail.self_status === EventDetailSelfStatus.host && (
                  <div style={{ marginLeft: "auto" }}>
                    <span
                      style={{
                        cursor: "pointer",
                        color: colors.orange,
                        textDecoration: "underline",
                      }}
                      onClick={() => setRemoveUserEvent(user_event)}
                    >
                      Remove
                    </span>
                  </div>
                )}
              </div>
            ))}
        </div>
      </CustomDrawer>
      <CustomDialog
        open={Boolean(removeUserEvent)}
        handleClose={() => setRemoveUserEvent(null)}
      >
        <div style={{ fontWeight: 800 }}>
          Are you sure you want to remove {removeUserEvent?.nickname} from your
          guest list?
        </div>
        <div style={{ marginTop: 30, textAlign: "center" }}>
          <RoundButton
            outlined
            backgroundColor={colors.red}
            color={colors.red}
            handleClick={handleRemove}
          >
            Remove
          </RoundButton>
        </div>
      </CustomDialog>
    </EventDetailContext.Provider>
  );
}

export default EventsDetail;
