import { createContext, useContext, useEffect, useState } from "react";
import RoundButton from "../components/RoundButton";
import { useUserHooks } from "../hooks/user";
import Header from "../components/Header";
import Horizontal from "../components/Horizontal";
import InfoComponent from "../components/InfoComponent";
import TextInput from "../inputs/TextInput";
import ImageUpload from "../inputs/ImageUpload";
import Avatar from "../components/Avatar";
import { useImageUpload } from "../hooks/image";
import { GlobalContext } from "../App";
import { colors } from "../hooks/color";
import CustomImage from "../components/CustomImage";
import { fetchData } from "../api";
import { getEmail } from "../util";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ReactComponent as EditSvg } from "../svgs/edit.svg";
import DefaultProfileCover from "../pngs/default_profile_cover.png";
import EventsDetailEdit from "./events/EventsDetailEdit";

interface ProfileEvent {
  event_id: number;
  event_code: number;
  image: string;
  title: string;
  host_name: string;
  host_image: string;
  hosting: boolean;
}

interface ProfileMedia {
  id: string;
  media_type: string;
  media_url: string;
  thumbnail_url: string;
}

interface ProfileProps {
  id: number;
  cover: string;
  image: string;
  nickname: string;
  email: string;
  instagram_id: string;
  hosted: number;
  attended: number;
  upcomings: ProfileEvent[];
  pasts: ProfileEvent[];
  incompletes: ProfileEvent[];
  not_goings: ProfileEvent[];
  medias: ProfileMedia[];
}

enum EntryPage {
  profile = "profile",
  account_info = "account_info",
  terms_and_condition = "terms_and_condition",
  privacy = "privacy",
  event_create = "event_create",
}

interface UserProfileProps {
  setPage: (page: EntryPage) => void;
}

function UserProfile({ setPage }: UserProfileProps) {
  const { putUserImage, putUserCover, signout } = useUserHooks();
  const { imageUpload } = useImageUpload();
  const { profile, refresh } = useContext(ProfileContext);

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <ImageUpload
        handleChange={(file) =>
          imageUpload(file)
            .then(({ location }) => putUserCover(location))
            .then(refresh)
        }
      >
        <div style={{ position: "relative", width: "100%" }}>
          <CustomImage
            width="100%"
            height={300}
            src={profile.cover || DefaultProfileCover}
          />
          <div style={{ position: "absolute", bottom: 30, right: 15 }}>
            <div
              style={{
                borderRadius: "50%",
                width: 28,
                height: 28,
                backgroundColor: colors.ivory,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              }}
            >
              <EditSvg />
            </div>
          </div>
        </div>
      </ImageUpload>
      <div
        style={{
          padding: 15,
          marginTop: -15,
          borderRadius: "20px 20px 0px 0px",
          flex: 1,
          backgroundColor: colors.black,
          textAlign: "center",
          zIndex: 1,
        }}
      >
        <div
          style={{
            marginTop: -60,
            display: "inline-flex",
            justifyContent: "center",
          }}
        >
          <ImageUpload
            handleChange={(file) =>
              imageUpload(file)
                .then(({ location }) => putUserImage(location))
                .then(refresh)
            }
          >
            <div
              style={{
                display: "flex",
                position: "relative",
              }}
            >
              <Avatar
                width={92}
                height={92}
                src={profile.image}
                style={{
                  border: `2px solid ${colors.ivory}`,
                }}
              />
              <div style={{ position: "absolute", bottom: 0, right: 0 }}>
                <div
                  style={{
                    borderRadius: "50%",
                    width: 28,
                    height: 28,
                    backgroundColor: colors.ivory,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <EditSvg />
                </div>
              </div>
            </div>
          </ImageUpload>
        </div>
        <div
          style={{
            marginTop: 15,
            fontSize: 24,
            fontWeight: 600,
          }}
        >
          {profile.nickname}
        </div>
        <div
          style={{
            marginTop: 30,
          }}
        >
          <div
            style={{
              backgroundColor: colors.grey,
              borderRadius: 20,
              padding: 10,
              color: colors.black,
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              fontWeight: 600,
              fontSize: 25,
            }}
          >
            <div style={{ flex: 1, position: "relative" }}>
              {profile.hosted}
              <div
                style={{
                  position: "absolute",
                  top: 50,
                  fontSize: 12,
                  left: 0,
                  right: 0,
                  color: colors.ivory,
                }}
              >
                Meals Hosted
              </div>
            </div>
            <div
              style={{ width: 1, height: 32, backgroundColor: colors.black }}
            ></div>
            <div style={{ flex: 1, position: "relative" }}>
              {profile.attended}

              <div
                style={{
                  position: "absolute",
                  top: 50,
                  fontSize: 12,
                  left: 0,
                  right: 0,
                  color: colors.ivory,
                }}
              >
                Meals Attended
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginLeft: -15, marginRight: -15 }}>
          <Horizontal marginTop={60} marginBottom={20} opacity={0.5} />
        </div>
        <div style={{ textAlign: "start" }}>
          <RoundButton
            handleClick={() => setPage(EntryPage.event_create)}
            backgroundColor={colors.yellow}
            color={colors.black}
          >
            Create Event
          </RoundButton>
          <Horizontal marginTop={20} marginBottom={20} opacity={0.5} />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => setPage(EntryPage.account_info)}
          >
            <div>Account Info</div>
            <ChevronRightIcon />
          </div>
          <Horizontal marginTop={20} marginBottom={20} opacity={0.5} />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => setPage(EntryPage.terms_and_condition)}
          >
            <div>Terms & Conditions</div>
            <ChevronRightIcon />
          </div>
          <Horizontal marginTop={20} marginBottom={20} opacity={0.5} />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => setPage(EntryPage.privacy)}
          >
            <div>Privacy</div>
            <ChevronRightIcon />
          </div>
          <Horizontal marginTop={20} marginBottom={20} opacity={0.5} />
          <div onClick={signout}>Sign Out</div>
        </div>
      </div>
    </div>
  );
}

interface EntryPageProps {
  handleBack: () => void;
}

function AccountInfo({ handleBack }: EntryPageProps) {
  const { imageUpload } = useImageUpload();
  const { putUserImage, putUserProfile, putUserCover } = useUserHooks();
  const [edit, setEdit] = useState<boolean>(false);
  const [nickname, setNickname] = useState<string>("");
  const { profile, refresh } = useContext(ProfileContext);

  useEffect(() => {
    setNickname(profile.nickname);
  }, [profile]);

  return (
    <>
      <Header
        backgroundGradient
        label="Account info"
        handleBack={handleBack}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
        }}
      />
      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <ImageUpload
          handleChange={(file) =>
            imageUpload(file)
              .then(({ location }) => putUserCover(location))
              .then(refresh)
          }
        >
          <div style={{ position: "relative", width: "100%" }}>
            <CustomImage
              width="100%"
              height={300}
              src={profile.cover || DefaultProfileCover}
            />
            <div style={{ position: "absolute", bottom: 30, right: 15 }}>
              <div
                style={{
                  borderRadius: "50%",
                  width: 28,
                  height: 28,
                  backgroundColor: colors.ivory,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                }}
              >
                <EditSvg />
              </div>
            </div>
          </div>
        </ImageUpload>
        <div
          style={{
            padding: 15,
            marginTop: -15,
            borderRadius: "20px 20px 0px 0px",
            flex: 1,
            backgroundColor: colors.black,
            textAlign: "center",
            zIndex: 1,
          }}
        >
          <div
            style={{
              marginTop: -60,
              display: "inline-flex",
              justifyContent: "center",
            }}
          >
            <ImageUpload
              handleChange={(file) =>
                imageUpload(file)
                  .then(({ location }) => putUserImage(location))
                  .then(refresh)
              }
            >
              <div
                style={{
                  display: "flex",
                  position: "relative",
                }}
              >
                <Avatar
                  width={92}
                  height={92}
                  src={profile.image}
                  style={{
                    border: `2px solid ${colors.ivory}`,
                  }}
                />
                <div style={{ position: "absolute", bottom: 0, right: 0 }}>
                  <div
                    style={{
                      borderRadius: "50%",
                      width: 28,
                      height: 28,
                      backgroundColor: colors.ivory,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    }}
                  >
                    <EditSvg />
                  </div>
                </div>
              </div>
            </ImageUpload>
          </div>
          <div
            style={{
              marginTop: 15,
              fontSize: 24,
              fontWeight: 600,
            }}
          >
            {profile.nickname}
          </div>
          <Horizontal marginTop={30} marginBottom={15} />
          <div style={{ textAlign: "start" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontWeight: 600,
                }}
              >
                Name
              </div>
              <div
                style={{
                  fontSize: 14,
                  fontWeight: 600,
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => setEdit(true)}
              >
                Edit
              </div>
            </div>
            {!edit && (
              <div style={{ marginTop: 10, fontSize: 14 }}>{nickname}</div>
            )}
            {edit && (
              <div style={{ marginTop: 10, fontSize: 14 }}>
                <TextInput handleChange={setNickname} value={nickname} />
                <div style={{ marginTop: 10 }}>
                  <RoundButton
                    backgroundColor={colors.ivory}
                    color={colors.black}
                    handleClick={() =>
                      putUserProfile({ nickname }).then(() => setEdit(false))
                    }
                  >
                    Save
                  </RoundButton>
                </div>
              </div>
            )}
          </div>
          <Horizontal marginTop={15} marginBottom={15} />
          <InfoComponent label="Email" value={profile.email} />
          <Horizontal marginTop={15} marginBottom={15} />
          {/* <InstagramConnect />
        <Horizontal marginTop={15} marginBottom={15} /> */}
        </div>
      </div>
    </>
  );
}

function TermsAndCondition({ handleBack }: EntryPageProps) {
  return (
    <>
      <Header
        backgroundGradient
        label="Terms & Conditions"
        handleBack={handleBack}
      />
      <div style={{ padding: 15, whiteSpace: "pre-line" }}>
        {`Welcome to Tably! By accessing or using our platform (the “Service”), you agree to comply with and be bound by these Terms and Conditions (the “Terms”). Please read them carefully before using Tably.

1. Acceptance of Terms

By creating an account, purchasing tickets, or using any feature of our Service, you agree to be bound by these Terms, along with our Privacy Policy. If you do not agree, please do not use the Service.

2. Eligibility

You must be at least 18 years old or have parental consent to use Tably. By using the Service, you represent that you meet these requirements.

3. Account Registration

You are required to create an account to access certain features of the Service. You agree to provide accurate and up-to-date information during registration. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.

4. Event and Ticket Policies

Ticket Purchases: By purchasing a ticket through the Service, you agree to abide by the event policies, including cancellation and refund terms specific to each event.

Refund Policy: All ticket sales are final unless specified otherwise. Refunds, if offered, are determined by the event organizer’s policy and may be subject to certain conditions.

Cancellations and Changes: Event organizers reserve the right to cancel or reschedule events. In the event of a cancellation, ticket holders will be notified and may be eligible for a refund based on the event’s cancellation policy.

5. Menu and Dietary Information

Tably allows hosts to create and share menus for their events. While we strive to provide accurate dietary and allergen information, we cannot guarantee accuracy or the absence of allergens. It is the responsibility of attendees to communicate dietary restrictions directly to the host.

6. Acceptable Use

You agree not to use the Service for any unlawful, fraudulent, or malicious purpose. Prohibited activities include:

Violating any applicable local, state, or international law

Engaging in any action that may harm or disrupt the Service or other users

Posting or sharing offensive, false, or misleading information

7. User Content

By sharing content on the platform (photos, reviews, etc.), you grant Tably a non-exclusive, worldwide, royalty-free license to use, reproduce, and display your content in connection with the Service. You retain ownership of your content.

8. Payment and Fees

Payment Processing: All ticket sales and other payments are processed by third-party payment providers. By using our Service, you agree to comply with the terms of these providers.

9. Limitation of Liability

Tably is not liable for any damages, including personal injury or property damage, arising from your attendance at events or use of the Service. Hosts are solely responsible for the safety and suitability of their events.

10. Privacy Policy

Your privacy is important to us. Please refer to our Privacy Policy for details on how we collect, use, and share your personal information.

11. Modifications to Terms and Service

Tably reserves the right to modify these Terms at any time. Changes will be posted on this page with an updated date. Your continued use of the Service constitutes acceptance of any updated Terms.

12. Governing Law

These Terms and any disputes arising from them will be governed by the laws of New York, without regard to its conflict of law principles.

13. Contact Us

If you have questions about these Terms, please contact us at ${getEmail()}.`}
      </div>
    </>
  );
}

function Privacy({ handleBack }: EntryPageProps) {
  return (
    <>
      <Header backgroundGradient label="Privacy" handleBack={handleBack} />
      <div style={{ padding: 15, whiteSpace: "pre-line" }}>
        {`Tably ("we," "our," or "us") values your privacy. This Privacy Policy explains how we collect, use, and protect your information when you use our platform (the “Service”). By using Tably, you consent to the practices described in this policy.

1. Information We Collect

    We only collect information that you provide directly to us. This may include:
    Account Information: When you create an account, we collect personal information, such as your name, email address, and password.
    Event Details: Information you provide related to events, such as event descriptions, guest lists, and menu preferences.
    Payment Information: Payment details necessary to process ticket purchases. Please note that all payment processing is handled by third-party providers, and we do not store your payment information.
    Communication Data: Any feedback, messages, or inquiries you submit through the Service.

2. How We Use Your Information

    Tably uses the information you provide solely to:
    Facilitate your use of the Service
    Manage and organize events, including ticket sales and menu customization
    Communicate with you regarding your account, events, or inquiries
    Improve our Service based on feedback and usage patterns
    We do not use or share your data for any external marketing, sales, or promotional purposes.

3. Sharing of Information

    We will not share your personal information with third parties, except:
    With Your Consent: When you expressly consent to sharing information with specific parties, such as sharing event details with guests.
    Service Providers: We may work with third-party providers to support functions such as payment processing and hosting. These providers are only permitted to use your data as needed to perform services on our behalf and are obligated to protect your information.
    Legal Requirements: If required by law, we may disclose information to comply with legal obligations or respond to legal requests, such as court orders or subpoenas.

4. Data Security

    Tably takes reasonable measures to protect your information from unauthorized access, loss, or misuse. However, no security system is foolproof, and we cannot guarantee complete security.

5. Data Retention

    We retain your information only as long as necessary to fulfill the purposes described in this Privacy Policy or as required by law. If you delete your account, we will delete your personal information unless we are required by law to retain it.

6. Changes to This Privacy Policy

    We may update this Privacy Policy periodically to reflect changes in our practices. We will notify you of any significant changes by posting the new policy on this page with the updated date. Your continued use of Tably after the policy is updated constitutes acceptance of the revised policy.

7. Contact Us

    If you have questions or concerns about this Privacy Policy, please contact us at ${getEmail()}.`}
      </div>
    </>
  );
}

interface ProfileContextProps {
  refresh: () => void;
  profile: ProfileProps;
}

const ProfileContext = createContext({} as ProfileContextProps);

function Profile() {
  const [page, setPage] = useState<EntryPage>(EntryPage.profile);
  const [profile, setProfile] = useState<ProfileProps | null>(null);
  const [render, setRender] = useState<number>(0);
  const { refreshUser } = useContext(GlobalContext);
  const refresh = () => {
    setRender((render) => render + 1);
    refreshUser();
  };

  useEffect(() => {
    fetchData<ProfileProps>("GET", `/profile`).then(setProfile);
  }, [render]);

  if (!profile) return null;
  return (
    <ProfileContext.Provider
      value={{
        refresh,
        profile,
      }}
    >
      {(() => {
        if (page === EntryPage.account_info)
          return <AccountInfo handleBack={() => setPage(EntryPage.profile)} />;
        if (page === EntryPage.terms_and_condition)
          return (
            <TermsAndCondition handleBack={() => setPage(EntryPage.profile)} />
          );
        if (page === EntryPage.privacy)
          return <Privacy handleBack={() => setPage(EntryPage.profile)} />;
        if (page === EntryPage.event_create)
          return (
            <EventsDetailEdit handleBack={() => setPage(EntryPage.profile)} />
          );
        return <UserProfile setPage={setPage} />;
      })()}
    </ProfileContext.Provider>
  );
}

export default Profile;
