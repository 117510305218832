import React, { ReactNode, useEffect, useState } from "react";
import CustomDialog from "./CustomDialog";
import { useUser } from "../hooks/user";
import { SigninComponent } from "../pages/Sign";

interface VerificationDialog {
  open: boolean;
  handleClose: () => void;
  callback?: () => void;
}

function SigninDialog({ open, handleClose, callback }: VerificationDialog) {
  return (
    <CustomDialog open={open} handleClose={handleClose}>
      <div style={{ fontWeight: 700 }}>
        Please sign in to continue your activity
      </div>
      <div style={{ marginTop: 30, display: "flex", justifyContent: "center" }}>
        <SigninComponent callback={callback} />
      </div>
    </CustomDialog>
  );
}

interface VerificationWrapperProps {
  children?: ReactNode;
  handleClick?: () => void;
  style?: React.CSSProperties;
}

function VerificationWrapper({
  children,
  handleClick,
  style,
}: VerificationWrapperProps) {
  const [openSignin, setOpenSignin] = useState<boolean>(false);
  const user = useUser();

  useEffect(() => {
    if (user) {
      setOpenSignin(false);
    }
  }, [user]);

  return (
    <>
      <div
        style={{
          display: "inline-flex",
          cursor: "pointer",
          ...style,
        }}
        onClick={() => {
          // check validation rule first
          if (!user) {
            return setOpenSignin(true);
          }
          if (handleClick) {
            handleClick();
          }
        }}
      >
        {children}
      </div>
      <SigninDialog
        open={openSignin}
        handleClose={() => setOpenSignin(false)}
        callback={handleClick}
      />
    </>
  );
}

export default VerificationWrapper;
