import { useState } from "react";
import TablyInitPng from "../pngs/tably_init.png";
import LogoIcon from "../pngs/logo.png";
import { SigninComponent } from "./Sign";
import { useUser } from "../hooks/user";
import { colors } from "../hooks/color";
import Home from "./Home";
import Profile from "./Profile";
import EventsDetailEdit from "./events/EventsDetailEdit";

enum Nav {
  home = "home",
  add = "add",
  profile = "profile",
}

function UserEntry() {
  const user = useUser();
  const [nav, setNav] = useState<Nav>(Nav.home);

  if (!user) return null;
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div style={{ flex: 1, overflow: "auto" }}>
          {nav === Nav.home && <Home />}
          {nav === Nav.add && (
            <EventsDetailEdit handleBack={() => setNav(Nav.home)} />
          )}
          {nav === Nav.profile && <Profile />}
        </div>
        <div
          style={{
            padding: 15,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderTop: `1px solid ${colors.grey}`,
          }}
        >
          {nav === Nav.home ? (
            <svg
              style={{ cursor: "pointer" }}
              width="27"
              height="20"
              viewBox="0 0 27 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M26.1667 9.13387V19.5H20.6391V12.1092V11.8413L20.416 11.6929L13.6103 7.16454L13.3333 6.98025L13.0564 7.16454L6.25063 11.6929L6.0276 11.8413V12.1092V19.5H0.5V9.13387L13.2748 0.637541L13.3333 0.693221L13.3918 0.637502L26.1667 9.13387Z"
                fill="#FFFAF6"
                stroke="#FFFAF6"
              />
            </svg>
          ) : (
            <svg
              style={{ cursor: "pointer" }}
              onClick={() => setNav(Nav.home)}
              width="27"
              height="20"
              viewBox="0 0 27 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M26.1667 9.13387V19.5H20.6391V12.1092V11.8413L20.416 11.6929L13.6103 7.16454L13.3333 6.98025L13.0564 7.16454L6.25063 11.6929L6.0276 11.8413V12.1092V19.5H0.5V9.13387L13.2748 0.637541L13.3333 0.693221L13.3918 0.637502L26.1667 9.13387Z"
                stroke="#FFFAF6"
              />
            </svg>
          )}
          {nav === Nav.add ? (
            <svg
              style={{ cursor: "pointer" }}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="23"
                height="23"
                rx="11.5"
                fill="#FFFAF6"
                stroke="#FFFAF6"
              />
              <path
                d="M11.4368 16.6784V13.2416H8V11.4272H11.4368V8H13.2512V11.4272H16.6688V13.2416H13.2512V16.6784H11.4368Z"
                fill="#242525"
              />
            </svg>
          ) : (
            <svg
              style={{ cursor: "pointer" }}
              onClick={() => setNav(Nav.add)}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="23"
                height="23"
                rx="11.5"
                stroke="#FFFAF6"
              />
              <path
                d="M11.4368 16.6784V13.2416H8V11.4272H11.4368V8H13.2512V11.4272H16.6688V13.2416H13.2512V16.6784H11.4368Z"
                fill="#FFFAF6"
              />
            </svg>
          )}
          {nav === Nav.profile ? (
            <svg
              style={{ cursor: "pointer" }}
              width="24"
              height="20"
              viewBox="0 0 24 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.6763 11.9486L17.3438 12.3791L17.8007 12.6742L18.1811 12.92L18.1916 12.9268L18.2026 12.9331C20.7716 14.4148 22.3994 16.6238 23.2027 19.5H18.9122C17.6873 16.8921 15.0152 15.0821 11.9231 15.0821C8.83099 15.0821 6.1589 16.8921 4.93391 19.5H0.6474C1.49017 16.6102 3.44725 14.1777 6.02923 12.6873L6.52465 12.4013L6.17496 11.9486C5.24772 10.7482 4.69615 9.25369 4.69615 7.63247C4.69615 3.70561 7.93337 0.5 11.9256 0.5C15.9179 0.5 19.1551 3.70561 19.1551 7.63247C19.1551 9.25369 18.6035 10.7482 17.6763 11.9486ZM15.1355 7.63247C15.1355 5.87495 13.6894 4.45909 11.9282 4.45909C10.1641 4.45909 8.72082 5.87526 8.72082 7.63247C8.72082 9.38969 10.1641 10.8059 11.9282 10.8059C13.6922 10.8059 15.1355 9.38969 15.1355 7.63247Z"
                fill="#FFFAF6"
                stroke="#FFFAF6"
              />
            </svg>
          ) : (
            <svg
              style={{ cursor: "pointer" }}
              onClick={() => setNav(Nav.profile)}
              width="24"
              height="20"
              viewBox="0 0 24 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.6763 11.9486L17.3438 12.3791L17.8007 12.6742L18.1811 12.92L18.1916 12.9268L18.2026 12.9331C20.7716 14.4148 22.3993 16.6238 23.2027 19.5H18.9122C17.6873 16.8921 15.0152 15.0821 11.9231 15.0821C8.83099 15.0821 6.1589 16.8921 4.93391 19.5H0.6474C1.49018 16.6102 3.44725 14.1777 6.02923 12.6873L6.52465 12.4013L6.17496 11.9486C5.24772 10.7482 4.69615 9.25369 4.69615 7.63247C4.69615 3.70561 7.93337 0.5 11.9256 0.5C15.9179 0.5 19.1551 3.70561 19.1551 7.63247C19.1551 9.25369 18.6035 10.7482 17.6763 11.9486ZM15.1355 7.63247C15.1355 5.87495 13.6894 4.45909 11.9282 4.45909C10.1641 4.45909 8.72082 5.87526 8.72082 7.63247C8.72082 9.38969 10.1641 10.8059 11.9282 10.8059C13.6922 10.8059 15.1355 9.38969 15.1355 7.63247Z"
                stroke="#FFFAF6"
              />
            </svg>
          )}
        </div>
      </div>
    </>
  );
}

function TableInit() {
  return (
    <>
      <div
        style={{
          height: "100%",
          backgroundImage: `url(${TablyInitPng})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            inset: 0,
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))",
          }}
        ></div>
        <div
          style={{
            position: "relative",
          }}
        >
          <img src={LogoIcon} alt="" style={{ height: 90 }} draggable={false} />
          <div
            style={{
              position: "absolute",
              top: "100%",
              left: 0,
              right: 0,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ fontSize: 20, fontWeight: 500 }}>
              meals made social
            </div>
            <div style={{ marginTop: 15 }}>
              <SigninComponent />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function Init() {
  const user = useUser();
  if (!user) return <TableInit />;
  return <UserEntry />;
}

export default Init;
